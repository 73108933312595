<template>
    <div class="w-100 h-100" id="viewer"></div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { userSession } from 'o365-modules';

export interface Props {
    pdfSource: Blob
};

const props = defineProps<Props>();

onMounted(() => {
    WebViewer(
        {
            path: 'nt/cdn/libs/apryse/10.9.0',
            initialDoc: props.pdfSource,
            backendType:'ems',
            annotationUser: userSession.name,
            streaming: true
        },
        document.getElementById('viewer'))
    .then(function(instance) {
        let documentViewer = instance.Core.documentViewer;
        let annotationManager = instance.Core.annotationManager;
    })
})
</script>